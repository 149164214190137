import { useEffect } from 'react';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import { useEpisodesCountPerUserQuery } from 'src/generated/graphql';

export default function useCanCreateEpisode() {
  const { data } = useEpisodesCountPerUserQuery({
    fetchPolicy: 'cache-first',
  });
  const { permissions, refetchPermissions } = usePermissionsContext();

  useEffect(() => {
    if (!permissions) {
      refetchPermissions();
    }
  }, [permissions, refetchPermissions]);

  const numEpisodes = data?.episodesCountPerUser ?? 0;
  const episodeLimit =
    permissions?.episodeLimit === null ? Infinity : (permissions?.episodeLimit ?? 0);
  return permissions && numEpisodes < episodeLimit;
}
