import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { CachePersistor } from 'apollo3-cache-persist';
import localForage from 'localforage';
import omitDeep from '../utils/omitDeep';

const cache = new InMemoryCache({
  typePolicies: {
    Episode: {
      fields: {
        timeline: {
          // replace the existing timeline with the incoming one when it changes
          // (if we don't define this explicitly, we get a warning in console)
          merge: (_existing, incoming = []) => incoming,
        },
      },
    },
    EpisodeFile: {
      keyFields: ['uniqueId'],
      fields: {
        audioTimeline: {
          // replace the existing audioTimeline with the incoming one when it changes
          // (if we don't define this explicitly, we get a warning in console)
          merge: (_existing, incoming) => incoming,
        },
        additionalFiles: {
          // replace the existing array with the incoming one when it changes
          // (if we don't define this explicitly, we get a warning in console)
          merge: (_existing, incoming = []) => incoming,
        },
      },
    },
    EpisodeMetaData: {
      merge: (existing = {}, incoming = {}) => ({ ...existing, ...incoming }),
    },
    SilenceItem: {
      // The ID of a SilenceItem is unique only within the context of the EpisodeFile
      // it belongs to, so we need to disable normalization for this type.
      // This avoids a problem when splitting a clip, where the two output
      // clips from the split would try to reference the same SilenceItem
      // in the Apollo cache.
      keyFields: false,
    },
    RemovedAudioItem: {
      // See comment for SilenceItem
      keyFields: false,
    },
    AudioItem: {
      // See comment for SilenceItem
      keyFields: false,
    },
    MuteItem: {
      // See comment for SilenceItem
      keyFields: false,
    },
    BleepItem: {
      // See comment for SilenceItem
      keyFields: false,
    },
    BackgroundItem: {
      // See comment for SilenceItem
      // This would become a problem when e.g. splitting a clip
      keyFields: false,
    },
    Podcast: {
      fields: {
        processingSettings: {
          // replace the existing object with the incoming ones when they change
          // (if we don't define this explicitly, we get a warning in console)
          merge: (_existing, incoming = []) => incoming,
        },
        categories: {
          merge: (_existing, incoming = []) => incoming,
        },
      },
    },
  },
});
const newPersistor = new CachePersistor({
  cache,
  storage: localForage,
  trigger: 'write',
});
await newPersistor.restore();
export const persistor = newPersistor;
export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    new ApolloLink((operation, forward) => {
      // remove all `__typename` properties from all queries
      if (operation.variables) {
        // eslint-disable-next-line no-param-reassign
        operation.variables = omitDeep(operation.variables, '__typename');
      }
      return forward(operation);
    }),
    onError(({ graphQLErrors }) => {
      if (
        graphQLErrors?.some(({ message }) => message.includes('AuthenticationError')) ||
        graphQLErrors?.some(({ extensions }) => extensions?.code === 'UNAUTHENTICATED')
      ) {
        clearCacheAndRedirect();
      }
    }),
    new RetryLink({
      attempts: {
        max: 20,
        retryIf: (error, operation) =>
          (error?.message?.includes('Failed to fetch') &&
            operation.operationName !== 'importPodcastRss' &&
            operation.operationName !== 'captivatePublish') || // Temporary workaround to stop retries on importPodcastRss, as it would create duplicate entries
          error?.message?.toLowerCase().includes('502 bad gateway'),
      },
    }),
    new HttpLink({
      uri: GQL_API_URL,
      credentials: 'include',
      fetch: (...args: any[]) => {
        // @ts-ignore
        return window.fetch(...args);
      },
    }),
  ]),
});

async function clearCacheAndRedirect() {
  await client.clearStore();
  await persistor.purge();
  window.location.pathname = '/login';
  document.cookie = 'is-alitu-admin=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
