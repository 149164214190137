import { Button as ChakraButton, Flex, Image, Text } from '@chakra-ui/react';
import { useShowCache } from 'src/apollo/cache';
import colors from 'src/theme/colors';
import getArtworkVariant from 'src/utils/getArtworkVariant';
import DefaultArtworkIcon from 'src/assets/icons/DefaultArtworkIcon';
import { useMyShowsContext } from 'src/components/my-shows/MyShowsContext';
import MyShowsModal from 'src/components/my-shows/MyShowsModal';
import RightChevron from 'src/assets/icons/RightChevron';
import { fonts } from 'src/styles/variables';

export default function MyShowsMenuButton({ menuState }: { menuState: 'open' | 'closed' }) {
  const show = useShowCache();
  const { isOpen, onOpen, onClose } = useMyShowsContext();
  return (
    <>
      <ChakraButton
        onClick={onOpen}
        variant="empty"
        justifyContent={menuState === 'open' ? 'flex-start' : 'center'}
        w="100%"
        borderRadius="12px"
        {...(menuState === 'open' && {
          background: 'rgba(255, 255, 255, 0.10)',
          margin: '0 auto',
          padding: '14px 12px',
        })}
        _hover={{ background: 'rgba(255, 255, 255, 0.15)' }}
        {...(menuState === 'closed' && { w: '48px', h: '48px' })}
      >
        {show?.artworkUrl ? (
          <Image
            src={
              // if artwork is from 3rd party feed, there are no variants
              show.host === 'alitu'
                ? `${getArtworkVariant(show.artworkUrl, '120px')}`
                : show.artworkUrl
            }
            alt="show cover art"
            width="36px"
            height="36px"
            borderRadius="8px"
            border="2px solid white"
          />
        ) : (
          <Flex
            w="36px"
            h="36px"
            borderRadius="8px"
            justifyContent="center"
            alignItems="center"
            bg={colors.podcastBlue}
            border="2px solid white"
          >
            <DefaultArtworkIcon />
          </Flex>
        )}
        {menuState === 'open' && (
          <Flex flexDir="column" alignItems="flex-start" ml="10px">
            <Text
              color="white"
              fontSize="11px"
              fontWeight="600"
              opacity="0.8"
              overflow="hidden"
              textOverflow="ellipsis"
              w="150px"
              textAlign="left"
            >
              {show?.name}
            </Text>
            <Text color="white" fontFamily={fonts.bold} fontSize="13px">
              Switch Shows
              <RightChevron ml="6px" height="8px" />
            </Text>
          </Flex>
        )}
      </ChakraButton>
      <MyShowsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
