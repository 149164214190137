import { useDisclosure } from '@chakra-ui/react';
import axios from 'axios';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import assert from 'src/utils/assert';
import packageJson from '../../../package.json';

export type CacheBusterContextType = { refresh: () => void } & Omit<
  ReturnType<typeof useDisclosure>,
  'onToggle'
>;
const CacheBusterContext = createContext<CacheBusterContextType | undefined>(undefined);

export function useCacheBusterContext(): CacheBusterContextType {
  const context = useContext(CacheBusterContext);
  assert(context, 'Cache buster context must be used as a child of CacheBusterContext');
  return context;
}

/**
 * @description
 * This provider should be near the top of the component tree.
 * It will check for updates on mount and every 20 seconds thereafter.
 */
export function CacheBusterProvider({ children }: { children: ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onOpen: disclosureOnOpen, onClose: disclosureOnClose, ...disclosure } = useDisclosure();
  const isFirstRun = useRef(true);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    async function fetchRealVersion() {
      const cachedVersion = packageJson.version;
      try {
        if (process.env.NODE_ENV !== 'production') {
          return;
        }
        const { data } = await axios({
          method: 'GET',
          url: `/meta.json`,
          withCredentials: true,
          headers: {
            'Cache-Control': 'no-cache',
          },
        });
        const realVersion = data?.version;
        if (realVersion && realVersion !== cachedVersion) {
          disclosureOnOpen();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Could not check current Alitu version: ${error}`);
      }
    }

    if (isFirstRun.current) {
      isFirstRun.current = false;
      fetchRealVersion();
    }
    // check every 20 seconds in case user leaves his tab open for days
    interval.current = setInterval(fetchRealVersion, 20000);
  }, [disclosureOnOpen]);

  const onClose = useCallback(() => {
    disclosureOnClose();
    if (interval.current) {
      // stop checking for Alitu updates
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [disclosureOnClose]);

  const cacheBusterContextValues = useMemo(
    () => ({ ...disclosure, refresh, onClose, onOpen: disclosureOnOpen }),
    [disclosure, onClose, disclosureOnOpen],
  );

  return (
    <CacheBusterContext.Provider value={cacheBusterContextValues}>
      {children}
    </CacheBusterContext.Provider>
  );
}

function refresh() {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(names => {
      names.forEach(name => caches.delete(name));
    });
  }
  // delete browser cache and hard reload
  // @ts-ignore - Firefox supports a non-standard force reload parameter - https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#location.reload_has_no_parameter
  window.location.reload(true);
}
