import { Global } from '@emotion/react';
import MontserratMedium from '../assets/fonts/Montserrat-Medium.woff';
import MontserratSemiBold from '../assets/fonts/Montserrat-SemiBold.woff';

export function Fonts() {
  return (
    <Global
      styles={`
        @font-face {
          font-family: 'Montserrat-Medium';
          src: url(${MontserratMedium}) format('woff');
        }
        @font-face {
          font-family: 'Montserrat-SemiBold';
          src: url(${MontserratSemiBold}) format('woff');
        }
      `}
    />
  );
}
