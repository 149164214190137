import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  HStack,
  FormHelperTextProps,
  Select,
  SelectProps,
  Text,
} from '@chakra-ui/react';
import { ChangeEventHandler, FocusEventHandler, forwardRef, ReactNode } from 'react';
import { fonts } from 'src/styles/variables';
import FieldErrorMessage from './FieldErrorMessage';

interface DropdownProps extends Omit<FormControlProps, 'onBlur' | 'onChange'> {
  value?: string;
  label?: string;
  errorMessage?: string;
  message?: string;
  children: ReactNode;
  isDisabled?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  size?: 'small' | 'large';
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  labelVariant?: string;
  labelProps?: FormLabelProps;
  helperProps?: FormHelperTextProps;
  selectProps?: SelectProps;
  isOptional?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

type DropdownRef = HTMLSelectElement;
const Dropdown = forwardRef<DropdownRef, DropdownProps>(
  (
    {
      value,
      placeholder,
      label,
      isDisabled,
      message,
      errorMessage,
      isInvalid,
      children,
      size,
      onBlur,
      onChange,
      labelVariant,
      labelProps,
      helperProps,
      selectProps,
      isOptional,
      leftIcon,
      rightIcon,
      ...props
    },
    ref,
  ) => (
    <FormControl isInvalid={isInvalid} isDisabled={isDisabled} {...props}>
      {label && (
        <FormLabel variant={labelVariant} {...labelProps} display="flex">
          {label}
          {isOptional && (
            <Text ml="5px" fontFamily={fonts.bold}>
              (optional)
            </Text>
          )}
        </FormLabel>
      )}
      <HStack spacing="8px">
        {leftIcon}
        <Select
          ref={ref}
          placeholder={placeholder}
          size={size}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          aria-label={selectProps?.['aria-label'] || placeholder || label}
          {...selectProps}
        >
          {children}
        </Select>
        {rightIcon}
      </HStack>
      {message && <FormHelperText {...helperProps}>{message}</FormHelperText>}
      {errorMessage && <FieldErrorMessage>{errorMessage}</FieldErrorMessage>}
    </FormControl>
  ),
);

export default Dropdown;
