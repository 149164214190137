import { colours, fonts } from 'src/styles/variables';

const Text = {
  baseStyle: {
    fontFamily: fonts.normal,
    color: 'podcastShade600',
    lineHeight: '24px',
    fontSize: '16px',
    textShadow: 'none',
  },

  sizes: {
    none: {},
    p1: {
      fontSize: ['16px', '18px'],
      lineHeight: ['24px', '30px'],
    },
    p2: {
      fontSize: ['12px', '16px'],
      lineHeight: ['18px', '24px'],
    },
    p3: {
      fontSize: ['11px', '13px'],
      lineHeight: ['18px', '18px'],
    },
    p4: {
      fontSize: ['9px', '11px'],
      lineHeight: ['12px', '18px'],
    },
  },
  variants: {
    primary: {},
    lighter: { color: 'podcastShade500' },
    dark: { color: 'podcastShade600' },
    darker: { color: 'podcastShade700' },
    bold: { fontFamily: fonts.bold },
    libraryHeading: {
      color: colours.purple,
      fontSize: '15px !important',
      letterSpacing: '0.4px',
    },
    popover: {
      fontFamily: fonts.normal,
      fontSize: '13px',
      lineHight: '18px',
      color: 'podcastShade400',
    },
    emptyFile: {
      fontSize: '13px',
      textAlign: 'center',
      alignSelf: 'center',
      fontFamily: fonts.bold,
      color: 'podcastShade400',
    },
    ghost: {
      color: 'podcastShade400',
    },
  },

  defaultProps: {
    variant: 'primary',
    size: 'none',
  },
};

export default Text;
