const unleashConfig = {
  url: 'https://unleash-edge.alitu.com/api/frontend',
  clientKey: process.env.UNLEASH_FRONTEND_API_TOKEN,
  refreshInterval: 15,
  appName: 'alitu',
  // local development environment uses staging
  environment: process.env.NODE_ENV === 'production' ? 'production' : 'staging',
};

export default unleashConfig;
