import { useParams } from 'react-router-dom';
import { Episode, EpisodeQuery, Exact, useEpisodeQuery } from 'src/generated/graphql';
import { QueryResult, ApolloQueryResult } from '@apollo/client';
import useApolloErrorHandler from './useApolloErrorHandler';

interface UseFetchEpisode extends QueryResult<EpisodeQuery, Exact<{ id: string }>> {
  episode?: Omit<Episode, 'logs'>;
  version?: number;
}

export default function useFetchEpisode(id?: string): UseFetchEpisode {
  const { episodeId: paramsEpisodeId = '', episodeVersion: paramsEpisodeVersion } = useParams();

  const version = paramsEpisodeVersion ? parseInt(paramsEpisodeVersion, 10) : undefined;
  const episodeId = id || paramsEpisodeId;

  // Always call the hook, but skip the actual query if episodeId is empty
  const { refetch, ...query } = useEpisodeQuery({
    fetchPolicy: 'cache-first', // cache first to prevent unnecessary requests between episodes and episode
    variables: {
      id: episodeId,
      version,
    },
    skip: !episodeId, // Skip the query execution if episodeId is empty
  });

  // Always call the error handler hook
  useApolloErrorHandler(query.error);

  // If episodeId is empty, ensure we return a consistent result
  if (!episodeId) {
    const mockRefetch = async (): Promise<ApolloQueryResult<EpisodeQuery>> => {
      return {
        data: null as unknown as EpisodeQuery,
        loading: false,
        networkStatus: 7,
      } as ApolloQueryResult<EpisodeQuery>;
    };

    return {
      ...query,
      loading: false,
      data: undefined,
      refetch: mockRefetch,
      episode: undefined,
    };
  }

  return { ...query, refetch, episode: query.data?.episode };
}
