import { Box, Button, Flex, IconButton, Image, Text, Divider } from '@chakra-ui/react';
import { useUserCache } from 'src/apollo/cache';
import AlituTickIcon from 'src/assets/icons/AlituTickIcon';
import DefaultArtworkIcon from 'src/assets/icons/DefaultArtworkIcon';
import TrashIcon from 'src/assets/icons/TrashIcon';
import { ShowFragmentFragment } from 'src/generated/graphql';
import colors from 'src/theme/colors';
import assert from 'src/utils/assert';
import getArtworkVariant from 'src/utils/getArtworkVariant';
import hexToRgb from 'src/utils/hexToRgb';
import { useMyShowsContext } from './MyShowsContext';
import RenewShowButton from './RenewShowButton';

export default function Show({
  show,
  onSwitchToShow,
}: {
  show: ShowFragmentFragment;
  onSwitchToShow: () => void;
}) {
  const user = useUserCache();
  assert(user, 'user not found in cache');
  const { setContentType, setShowToRemove, isMyAccount, onOpen } = useMyShowsContext();

  function getTitleWidth() {
    if (show.showExpiresAt) {
      if (isMyAccount) {
        return '140px';
      }
      return '200px';
    }
    return '300px';
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          {show.artworkUrl ? (
            <Image
              src={
                // if artwork is from 3rd party feed, there are no variants
                show.host === 'alitu'
                  ? `${getArtworkVariant(show.artworkUrl, '120px')}`
                  : show.artworkUrl
              }
              alt="show cover art"
              width="36px"
              height="36px"
              borderRadius="8px"
            />
          ) : (
            <Flex
              width="36px"
              height="36px"
              borderRadius="8px"
              justifyContent="center"
              alignItems="center"
              bg={colors.podcastBlue}
            >
              <DefaultArtworkIcon />
            </Flex>
          )}
          <Text
            as="h4"
            ml="15px"
            color={colors.podcastShade600}
            textOverflow="ellipsis"
            overflow="hidden"
            w={getTitleWidth()}
            whiteSpace="nowrap"
          >
            {show.name}
          </Text>
        </Flex>
        <Flex alignItems="center">
          {show.showExpiresAt && <RenewShowButton showId={show.id} />}
          {user.activeProjectId === show.id ? (
            <AlituTickIcon width="24px" height="24px" />
          ) : (
            <Flex>
              <Box>
                {!show.showExpiresAt && (
                  <IconButton
                    h="100%"
                    variant="flat"
                    bg={hexToRgb(colors.podcastRed600, 0.1)}
                    _hover={{ bg: hexToRgb(colors.podcastRed600, 0.05) }}
                    _active={{ bg: hexToRgb(colors.podcastRed600, 0.15) }}
                    onClick={() => {
                      setContentType('remove-show');
                      setShowToRemove(show.id);
                      if (isMyAccount) {
                        onOpen();
                      }
                    }}
                    aria-label="Remove show"
                    marginRight="10px"
                  >
                    <TrashIcon colour={colors.podcastRed600} />
                  </IconButton>
                )}
              </Box>
              <Button onClick={onSwitchToShow} variant="quaternary">
                Switch to
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Divider variant="signUp" mt="12px" mb="12px" />
    </>
  );
}
