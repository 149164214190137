import { useEffect } from 'react';
import { Episode, EpisodeFile, Host, Job, useEpisodesQuery } from 'src/generated/graphql';
import useApolloErrorHandler from 'src/hooks/useApolloErrorHandler';
import assert from 'src/utils/assert';
import { useEpisodesPageContext } from '../EpisodesPageContext';

export interface EpisodesQueryData {
  episodes: {
    id: Episode['id'];
    metaData: {
      name: Episode['metaData']['name'];
      summary: Episode['metaData']['description'];
      description: Episode['metaData']['description'];
      episodeNumber: Episode['metaData']['episodeNumber'];
    };
    timeline: {
      id: EpisodeFile['id'];
      uniqueId: EpisodeFile['uniqueId'];
    }[];
    file: Episode['file'];
    createdAt: NonNullable<Episode['createdAt']>;
    updatedAt: NonNullable<Episode['updatedAt']>;
  }[];
  filteredTotal: number;
  host: Host;
  draft: number;
  processing: number;
  all: number;
  complete: number;
}
export type EpisodesQueryVars = {
  name?: Episode['metaData']['name'];
  offset: number;
  limit?: number;
  order?: string;
  jobStatus?: Job['status'];
  isDraft?: boolean;
  projectId?: string;
};

export function useFetchEpisodes(shouldFetchOnFirstRender = false) {
  const [episodesPageContext, dispatch] = useEpisodesPageContext();
  const { refetch, ...query } = useEpisodesQuery({
    variables: {
      name: episodesPageContext.filter.name,
      order: episodesPageContext.filter.sort,
      limit: episodesPageContext.pagination.limit,
      offset: episodesPageContext.pagination.offset,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  useApolloErrorHandler(query.data ? undefined : query.error); // assume error is not very important if data is returned
  const { data } = query;

  useEffect(() => {
    if (!data) return;
    dispatch({
      type: 'set-pagination',
      pagination: {
        totalPages: Math.ceil(data.filteredTotal / episodesPageContext.pagination.limit),
      },
    });
  }, [data, dispatch, episodesPageContext.pagination.limit]);

  useEffect(() => {
    if (shouldFetchOnFirstRender === true) {
      // force first api call, despite cache
      refetch();
    }
  }, [refetch, shouldFetchOnFirstRender]);

  useEffect(() => {
    dispatch({ type: 'set-page-loading', loading: query.loading });
  }, [episodesPageContext.loading, query.loading, dispatch]);

  // searching episodes by name
  useEffect(() => {
    refetch({ name: episodesPageContext.filter.name, offset: 0 }).then(
      () =>
        // go back to first page after searching
        dispatch({ type: 'set-pagination', pagination: { offset: 0 } }), // go back to first page when sorting
    );
  }, [episodesPageContext.filter.name, refetch, dispatch]);

  // sort episodes order
  useEffect(() => {
    if (episodesPageContext.filter.sort) {
      dispatch({ type: 'set-pagination', pagination: { offset: 0 } }); // go back to first page when sorting
      refetch({ order: episodesPageContext.filter.sort, offset: 0 });
    }
  }, [episodesPageContext.filter.sort, refetch, dispatch]);

  // poll when an episode is processing
  useEffect(() => {
    const processingStatuses = ['creating', 'created', 'pending', 'processing', 'uploading'];
    const episodes = query.data?.episodes;
    if (!episodes) return;
    const episodeIsProcessing = episodes.some(episode => {
      assert(episode, 'cannot find episode');
      return episode.file?.audioJob?.status
        ? processingStatuses.includes(episode.file.audioJob.status)
        : false;
    });
    if (episodeIsProcessing) {
      query.startPolling(4000);
    } else {
      query.stopPolling();
    }
  }, [query]);

  // filter episodes by job type
  useEffect(() => {
    refetch({
      jobStatus: episodesPageContext.filter.type,
      isDraft: episodesPageContext.filter.isDraft,
    });
  }, [episodesPageContext.filter.isDraft, episodesPageContext.filter.type, refetch]);

  return {
    ...query,
    refetch,
    totalEpisodes: query.data?.filteredTotal,
    filterCount: {
      all: query.data?.all,
      draft: query.data?.draft,
      processing: query.data?.processing,
      complete: query.data?.complete,
    },
    /**
     * When refetching (networkStatus 4) and episodes cache is empty, return the previous episodes cache
     * This will prevent 'flashing' content when the cache is stale or inconsistent with server state
     */
    episodes:
      query.networkStatus === 4 && !query.data?.episodes
        ? (query.previousData?.episodes ?? [])
        : (query.data?.episodes ?? []),
  };
}
